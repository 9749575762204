import React from 'react';
import { LogoParkeo } from '../img/index';

function Accueil() {
  return (
    <div className="App">
        <header className="App-header">
        <img src={LogoParkeo} className="App-logo" alt="logo" />
        </header>
    </div>
  )
}

export default Accueil;